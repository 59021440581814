.usersTable th,
td,
table {
  border: 1px solid;
}

.usersTable p {
  text-wrap: wrap;
  overflow-wrap: break-word;
  max-inline-size: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.usersTable .aaa {
  max-height: 150px;
}

.usersTable th {
  padding: 4px 10px;
}

th.id {
  min-width: 50px;
}

th.name {
  min-width: 200px;
}

th.email {
  min-width: 300px;
}

th.role {
  min-width: 100px;
}

th.created {
  min-width: 24px;
}

th.updated {
  min-width: 24px;
}

.usersTable td {
  padding: 5px 10px;
  margin: 5px 0;
}
