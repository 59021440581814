.usersTable {
  width: 100%;
  border-collapse: collapse;
}

.usersTable th,
.usersTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.usersTable th {
  background-color: #f2f2f2;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
}

.usersTable .odd {
  background-color: #f9f9f9;
}

.usersTable .even {
  background-color: #fff;
}
