.detail {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  background-color: transparent;
  border: 0px solid;
  outline: none;
}

.detail h1 {
  font-size: 32px;
  color: #e1e1e1;
  line-height: 32px;
  font-weight: 600;
  margin-left: 5%;
  margin-right: 5%;
}

.detail h3 {
  margin-top: 48px;
  margin-bottom: 80px;
  color: var(--blue);
  font-size: 24px;
  margin-left: 5%;
  margin-right: 5%;
}

@media only screen and (max-width: 921px) {
  .detail h1 {
    font-size: 24px;
    line-height: 56px;
  }

  .detail h3 {
    font-size: 24px;
    line-height: 56px;
    max-width: 100%;
    font-weight: 400;
  }
}
