.popupDelete {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgb(40, 40, 40);
    border-radius: 20px;
    padding: 48px 64px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    animation: fadeIn 1s;
}

.popupDelete p {
    color: white;
    text-align: center;
    line-height: 32px;
}

.popupDelete button {
    border-radius: 6px;
    padding: 12px 16px;
    font-size: 0.8rem;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    background-color: black;
    outline: 1px solid rgba(255, 255, 255, 0.5);
}

.popupDelete button:last-child {
    background-color: rgba(255, 0, 0, 0.6);
}
