.content .text p {
    margin-left: 5%;
    margin-right: 5%;
    color: #e1e1e1;
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
    line-height: 48px;
}

.content input {
    margin-left: 5%;
    margin-right: 5%;
    align-self: center;
    font-size: 0.8rem;
    margin-top: 26px;
    margin-bottom: 26px;
}

.content .green {
    color: var(--lite_green75);
}

h4 {
    font-size: 20px;
    color: #e1e1e1;
    margin-left: 5%;
    margin-right: 5%;
}

.test {
    display: flex;
    align-items: center;
    /* background-color: white; */
}

.test .error {
    color: rgb(255, 68, 0);
    opacity: 1;
    position: relative;
    left: -44px;
    font-style: italic;
    font-size: 0.85rem;
}

@media only screen and (max-width: 921px) {
    .content .text p {
        font-size: 0.8rem;
        line-height: 40px;
    }

    .test .error {
        left: -16px;
    }
}
