.logs .sort {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  color: white;
  font-size: 0.8rem;
  width: auto;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 80px;
}

.log {
  max-width: 900px;
  margin: auto;
  margin-top: 20px;
}

.log_detail {
  background-color: #7e7e7e;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  gap: 32px;

  margin-bottom: 48px;
  outline: 2px solid rgba(0, 187, 255, 0.65);
}

.log_detail .log_heading {
  min-width: 400px;
}

.log_detail h1 {
  font-size: 24px;
  line-height: 48px;
  font-weight: 600;
  width: 100%;
}

.log_detail p {
  color: white;
  font-size: 0.8rem;
  margin-top: 24px;
  font-weight: 200;
  line-height: 40px;
}

.log_detail .tags {
  height: auto;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  border-radius: 12px;

  outline: 2px solid rgba(0, 187, 255, 0.1);
}

.log_detail .tag {
  background-color: var(--lite_green75);
  padding: 2px 20px;
  border-radius: 16px;
  align-self: flex-start;
}

.tag p {
  display: table-cell;
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}

.log_heading a {
  text-decoration-color: white;
}

.logsMap .warning {
  margin: 0 auto;
  text-align: center;
  font-size: 15px;
  font-style: italic;
  line-height: 20px;
  margin-bottom: 32px;
  color: rgb(137, 131, 22);
}

@media only screen and (max-width: 921px) {
  .log_detail .log_heading {
    min-width: 100px;
  }

  .log_detail {
    flex-direction: column;
    margin-left: 2%;
    margin-right: 2%;
  }

  .log_detail h1 {
    font-size: 23px;
    line-height: 32px;
  }

  .log_detail p {
    font-size: 0.8rem;
    line-height: 32px;
  }

  .log_detail .tag {
    padding: 8px 20px;
    height: auto;
  }

  .tag p {
    font-size: 15px;
    line-height: 24px;
  }
}

.logsMap {
  min-height: 38vh;
}

.btn_normal {
  color: var(--blue);
  cursor: pointer;
}

.btn_white {
  color: white;
}
