.message_app .messages {
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 4rem;
}

.message_app .message {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: black;
    padding: 40px 48px;
    border-radius: 8px;
    min-height: 360px;
    max-height: 500px;
    outline: 1px solid rgba(0, 186, 253, 0.7);
    /* background-color: white; */
}

.message_app .w40 {
    width: 50%;
    padding: 4px 8px;
    /* background: white; */
}

.message_app .w60 {
    width: 100%;
    padding: 12px 8px;
    text-align: justify;
    padding: 20px;
    overflow: auto;
}

.message_app .line {
    background-color: var(--blue);
    opacity: 0.5;
    margin: 0 64px;
    width: 2px;
    /* margin: auto 64px; */
    height: 1;
}

.message_app .name {
    font-weight: 600;
    color: var(--lite_green75);
    /* background-color: white; */
    width: 300px;
}

.w60::-webkit-scrollbar-track {
    border: 1px solid #000000;
    padding: 2px 0;
    background-color: #000000;
}

.w60::-webkit-scrollbar {
    width: 10px;
}

.w60::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #737272;
    border: 1px solid #000;
}

.message_app .archive {
    background-color: rgba(0, 0, 255, 0.8);
    padding: 0 8px;
    border-radius: 4px;
    margin-top: 8px;
    color: white;
    font-weight: 600;
    outline: 1px solid rgba(255, 255, 255, 0.5);
}

.message_app .delete {
    background-color: rgba(255, 0, 0, 0.8);
    border-radius: 4px;
    padding: 0 8px;
    margin-top: 8px;
    font-weight: 600;
    outline: 1px solid rgba(255, 255, 255, 0.5);
}

.message .action {
    display: flex;
    gap: 4px;
    flex-direction: column;
    max-width: 200px;
}

.message_app .red-line {
    outline: 1px solid rgba(255, 0, 0, 0.7);
}

.message_app .deleteMessages {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    font-weight: 600;
    margin: 64px auto 0;
    padding: 16px 8px;
    border-radius: 4px;
    outline: 1px solid black;
    font-size: 0.9rem;
}

.scrollTop {
    color: white;
    background-color: #00bafd84;
    position: fixed;
    padding: 10px;
    bottom: 40px;
    border-radius: 8px;
    font-size: 0.8rem;
    right: 20px;
    cursor: pointer;
    animation: fadeIn 1s;
    z-index: 3;
}

.message_app .logs {
    background-color: black;
    height: 400px;
    padding: 12px 5%;
    /* outline: 1px solid blue; */
    /* margin-bottom: 5%; */
    overflow-x: auto;
    /* overflow-y: auto; */
    /* max-height: 100vh; */
}

.logs .logs-text {
    line-height: 24px;
    margin-top: 10px;
}

.logs .logs-delete {
    line-height: 24px;
    margin-top: 10px;
}

.message .padding {
    padding: 40px 2%;
    background-color: black;
    max-width: 1440px;
    margin: 80px auto;
    border-radius: 4px;
}

.logs::-webkit-scrollbar-track {
    border: 1px solid #000000;
    padding: 2px 0;
    background-color: #000000;
}

.logs::-webkit-scrollbar {
    width: 10px;
}

.logs::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #737272;
    border: 1px solid #000;
}

@media only screen and (max-width: 1025px) {
    .message_app .message {
        font-size: 0.7rem;
    }
    .message_app .name {
        font-size: 1.5rem;
        width: 200px;
    }
    .action .archive,
    .action .delete {
        font-size: 0.7rem;
    }
    .message_app .message {
        margin-left: 2%;
        margin-right: 2%;
    }
}

@media only screen and (max-width: 801px) {
    .message_app .message {
        flex-direction: column;
        min-height: 600px;
    }
    .message_app .w60 {
        margin-top: 40px;
        outline: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 4px;
    }
    .message_app .w40 {
        /* background-color: white; */
        width: auto;
        text-align: center;
    }
    .message_app .name {
        /* background-color: white; */
        width: 100%;
    }
    .message_app .action {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 200px;
    }
}
