.stopwatch h1 {
  color: rgba(255, 255, 0, 0.7);
  text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.2),
    1px -1px 0 rgba(255, 255, 255, 0.2), -1px 1px 0 rgba(255, 255, 255, 0.2),
    1px 1px 0 rgba(255, 255, 255, 0.2);
}

.stopwatch .buttons {
  display: flex;
  flex-direction: column;
  /* background-color: white; */
  justify-content: center;
}

.stopwatch .label {
  background-color: rgba(255, 255, 255, 0.7);
  /* display: inline-block; */
  text-align: center;
  border-radius: 12px;
  max-width: 520px;
  color: black;
  margin: auto;
  margin-top: 40px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  margin-bottom: 40px;
  padding: 20px;
}

.label input {
  min-width: 280px;
  max-width: 400px;
  outline: 3px solid black;
  padding: 3px;
  font-weight: 400;
}

.buttons button {
  margin: auto;
  margin-top: 12px;
  /* width: 200px; */
  border-radius: 5px;
  outline: 1px solid rgba(255, 255, 255, 0.3);
  color: black;
  padding: 8px;
  width: 200px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  background-color: var(--lite_green75);
}

.buttons .sleep {
  background-color: rgba(0, 187, 255, 0.8);
}

.buttons .rest {
  background-color: rgba(255, 166, 0, 0.85);
}

.robotoFont {
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}

/* table {
    border-collapse: collapse;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 40px;
}

th,
td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    border: 1px solid black;
} */

td:nth-child(2),
th:nth-child(2) {
  width: 120px;
}

td:nth-child(1),
th:nth-child(1) {
  width: 40px;
}

td:nth-child(3),
th:nth-child(3) {
  width: 40px;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.newActivity {
  background-color: white;
  opacity: 0.8;
}

.activity {
  background-color: var(--lite_green75);
  opacity: 0.9;
}

.rest {
  background-color: rgba(255, 166, 0, 0.85);
  opacity: 0.9;
}

.sleep {
  background-color: rgba(0, 187, 255, 0.8);
  opacity: 0.9;
}

@media only screen and (max-width: 375px) {
  .label input {
    min-width: 240px;
  }
}
