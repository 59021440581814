/* *,
*:before,
*:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
} */
input[type="date"] {
    background-color: var(--lite_green);
    padding: 15px;
    font-family: "Roboto", sans-serif;
    color: black;
    font-size: 1rem;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    opacity: 0.9;
}

::-webkit-calendar-picker-indicator {
    cursor: pointer;
    /* background-color: rgba(0, 0, 0, 0.1); */
    /* outline: 2px solid black; */
    padding: 5px;
    border-radius: 3px;
}

.date {
    display: flex;
    justify-content: center;
    /* width: fit-content; */
    /* margin: 0 auto; */
    margin-top: -60px;
    font-size: 0.8rem;
}

.date label {
    color: white;
    margin: 0 8px;
}

.content .currentDate {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    margin: 0 auto;
    margin-top: 40px;
}

@media only screen and (max-width: 375px) {
    .date {
        font-size: 0.65rem;
    }
}
