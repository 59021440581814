@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.message_portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgb(40, 40, 40);
  border-radius: 20px;
  width: 880px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.sent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgb(40, 40, 40);
  border-radius: 20px;
  padding: 48px 64px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: fadeIn 1s;
}

.sent p {
  color: var(--lite_green75);
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word; /* Allow text to wrap to the next line */
  display: -webkit-box; /* Enable flexbox model */
  -webkit-box-orient: vertical; /* Set the orientation to vertical */
  -webkit-line-clamp: 3; /* Number of lines to show */
  line-clamp: 3; /* Number of lines to show */
  text-align: center;
  line-height: 32px;
}

.error_message p {
  color: rgb(255, 68, 0);
  text-align: center;
  font-style: italic;
  font-size: 0.7rem;
  opacity: 0.9;
  justify-content: center;
  word-wrap: break-word; /* Allow text to wrap to the next line */
  display: -webkit-box; /* Enable flexbox model */
  -webkit-box-orient: vertical; /* Set the orientation to vertical */
  -webkit-line-clamp: 3; /* Number of lines to show */
  line-clamp: 3; /* Number of lines to show */
}

.error_message input {
  font-size: 0.7rem;
}

.error_message p:first-child {
  margin-bottom: 16px;
  font-style: normal;
  font-size: 0.8rem;
}

.sent .close_button {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 48px;
  background-color: var(--lite_green75);
  color: black;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 0.8rem;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.close {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 16px;
  top: 16px;
  color: white;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  height: 100%;
}

.box {
  max-width: 600px;
  margin: auto;
  padding-top: 32px;
  padding-bottom: 32px;
}

.box p {
  margin-top: 32px;
  color: var(--lite_green75);
}

.box p:first-child {
  margin-top: 32px;
}

.box input {
  margin-top: 16px;
  width: 320px;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "Barlow", sans-serif;
  font-size: 0.8rem;
}

.box textarea {
  margin-top: 16px;
  width: 100%;
  height: 160px;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "Barlow", sans-serif;
  font-size: 0.8rem;
}

.box .button {
  padding-top: 32px;
  width: 100%;
  margin: auto;
}

.button button {
  display: flex;
  justify-content: center;
  margin: auto;
  background-color: var(--lite_green75);
  color: black;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 0.8rem;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.message_portal .error {
  color: rgb(255, 68, 0);
  opacity: 1;
  font-style: italic;
  font-size: 0.7rem;
}

.message-text {
  text-align: center;
  word-wrap: break-word; /* Allow text to wrap to the next line */
  overflow: hidden; /* Hide the overflowed content */
  display: -webkit-box; /* Enable flexbox model */
  -webkit-box-orient: vertical; /* Set the orientation to vertical */
  -webkit-line-clamp: 3; /* Number of lines to show */
  line-clamp: 3; /* Number of lines to show */
  text-overflow: ellipsis; /* Add ellipsis (...) to indicate overflow */
}

@media only screen and (max-width: 921px) {
  .message_portal {
    width: 96%;
  }

  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
    width: 100%;
    margin: 0 auto;
  }

  .box input,
  .box textarea {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .box .margin {
    margin-left: 5%;
  }

  .sent {
    padding: 24px 32px;
    width: 85%;
  }
}
