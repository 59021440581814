.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.container .paragraph {
  margin: auto;
  margin-bottom: 16px;
  margin-top: 32px;
  color: var(--blue);
}

.center_div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center_div .text {
  font-weight: 600;
  color: var(--blue);
  text-decoration: none;
  position: absolute;
  left: 0;
  top: 4px;
}

.center_div .input {
  font-weight: 600;
  text-decoration: none;
  font-size: 0.7rem;
  margin-left: 45px;
  /* width: 50%; */
}

.border_login {
  border: 1px solid rgba(12, 251, 183, 0.5);
}

.center_div .button {
  display: flex;
  justify-content: center;
  background-color: var(--lite_green75);
  color: black;
  border: 1px solid black;
  border-radius: 6px;
  padding: 16px 24px;
  font-size: 0.8rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
}

.center_div .error {
  color: rgb(255, 68, 0);
  font-style: italic;
  font-size: 0.7rem;
  opacity: 0.9;
  left: 15%;
}

.center_div .padding {
  padding-left: 8px;
}
