/* Loading.css */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Black transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.loading-gif {
  width: 50px; /* Adjust size as necessary */
  height: 50px;
}
