h1 {
    color: white;
}

.not_found {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    /* background-color: blue; */
    padding: 46vh 0;
    text-align: center;
    vertical-align: center;
}
