.ipTable {
  width: 100%;
  border-collapse: collapse;
}

.ipTable th,
.ipTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.ipTable th {
  background-color: #f2f2f2;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
}

@keyframes fadeInSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.filter-ip-table {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 400px;
  animation: fadeInSlideDown 0.3s ease-out;
}
