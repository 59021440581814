.info1 {
  max-width: 1920px;
  margin: 0 auto;
  height: auto;
  margin-top: 120vh;
}

.info1 .heading {
  text-shadow: 0px 100px 80px rgba(0, 0, 0, 0.51),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.366616),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.304015),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.255),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.205985),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.143384);
  color: var(--blue);
  opacity: 0.8;
  margin-left: 5%;
  margin-right: 5%;
  line-height: 55px;
}

.info1 .body {
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  margin-top: var(--D80p);
  z-index: 5;
  /* padding-top: 8px; */
  padding: 64px 0;
  border-radius: 16px;
  min-height: 0vh;
  max-height: auto;
  margin-left: 5%;
  margin-right: 5%;
}

.body p {
  padding: 32px 0;
  margin-left: 5%;
  margin-right: 5%;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  max-width: 100vh;
  color: #e1e1e1;
  text-align: justify;
}

.image {
  position: relative;
  background-image: url("../../assets/connections.png");
  width: 62.5vh;
  height: 43vh;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  margin-left: 55%;
}

.cta {
  margin: 0 auto;
  margin-top: 2vh;
  background-color: #1ac2ff;
  opacity: 0.9;
  text-decoration: underline;
  padding: 16px 32px;
  width: 200px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid black;
  margin-top: 20vh;
  cursor: pointer;
}

.cta p {
  text-align: center;
  margin: 0 auto;
  font-weight: 600;
  line-height: 32px;
  color: #000000;
}

.cta:hover {
  background-color: var(--blue);
  opacity: 0.7;
}

/* responsive */

@media only screen and (max-width: 2000px) {
  .body {
    margin-left: 2%;
    margin-right: 2%;
  }

  .info1 p {
    max-width: 85%;
  }
}

@media only screen and (max-width: 1281px) {
  .info1 p {
    max-width: 100%;
  }

  .cta {
    margin-top: 200px;
  }

  .info1 .body {
    border-radius: 16px;
  }

  .body {
    margin-left: 5%;
    margin-right: 5%;
  }

  .imageRes {
    max-width: 640px;
    margin: 5% auto;
  }
}

/* mobile-phone */

@media only screen and (max-width: 921px) {
  .info1 {
    height: auto;
    margin-top: 120vh;
  }

  .info1 p {
    font-size: 0.8rem;
  }

  .info1 .heading {
    font-size: 32px;
  }

  .body p {
    font-size: 0.8rem;
    font-weight: 400;
    max-width: 100%;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
  }

  .cta p {
    text-align: center;
    margin: 0 auto;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
  }

  .body {
    margin: 0 auto !important;
    margin-top: var(--D80p) !important;
    border-radius: 0px !important;
  }

  .imageRes {
    max-width: 640px;
    margin: 5% auto;
    padding: 0 32px;
  }
}
